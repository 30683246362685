//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BigNumber from "bignumber.js";

import {
  getPosPoolContract,
  conflux,
  Drip,
  getPosAccountByPowAddress,
} from "../../utils/cfx";
import {
  getCfxByVote,
  calculateGasMargin,
  getFee,
  getApy,
  getDateByBlockInterval,
  getMax,
  getPrecisionAmount,
} from "../../utils";

export default {
  name: "About",
  data() {
    return {
      conflux: null,
      posPoolContract: null, //矿池
      active: 0,

      num: "", // 货币估算
      num2: "",
      gusuan: 0,
      coins: CFX_COINS, // 货币估算类型
      coins2: "",
      coins3: CFX_COINS,
      coins4: CFX_COINS,
      option1: [
        { text: "ACFX", value: "" },
        { text: "CFX", value: CFX_COINS },
        { text: "FC", value: "" },
        { text: "ArtT", value: "" },
      ],
      option2: [
        { text: "ACFX", value: "" },
        { text: "CFX", value: CFX_COINS },
        { text: "FC", value: "" },
        { text: "ArtT", value: "" },
      ],
      option3: [
        { text: "ACFX", value: "" },
        { text: "CFX", value: CFX_COINS },
        { text: "FC", value: "" },
        { text: "ArtT", value: "" },
      ],
      option4: [
        { text: "ACFX", value: "" },
        { text: "CFX", value: CFX_COINS },
        { text: "FC", value: "" },
        { text: "ArtT", value: "" },
      ],
      num21: "",
      num22: "",
      num23: "",
      num24: "",
      lpsum: "",
      singlelpsum1: "",
      singlelpsum2: "",
      singlelpsum3: "",
      singlelpsum4: "",
      amount: 0,
      balance: 0,
      mybalance1: 0,
      mybalance2: 0,
      mybalance3: 0,
      mybalance4: 0,
      mybalance: 0,
      isACFX: false,
      isFC: false,
      isArtT: false,
      activeNames: ["1"],
      activeNames2: ["1"],
      activeNames3: ["1"],
      activeNames4: ["1"],
      activeNames5: ["1"],
      activeNames6: ["1"],
      data: {
        labels: [],
        datasets: [
          {
            label: "acfx:cfx",
            backgroundColor: "rgb(255, 0, 0)",
            borderColor: "rgb(255, 0, 0)",
            data: [],
          },
          {
            label: "fc:cfx",
            data: [],
            backgroundColor: "rgb(0, 0, 255)",
            borderColor: "rgb(0, 0, 255)",
          },
          {
            label: "artT:cfx",
            data: [],
            backgroundColor: "rgb(0, 255, 0 )",
            borderColor: "rgb(0, 255, 0 )",
          },
        ],
      },
      LPamount: "",
      LPamount2: "",
      LPBalance: "",
      LPBalanceFull: "",
      LPTotalSum: "",
      profit: "",
      LPstaked: "",
      LPstakedFull:"",
    };
  },
  mounted() {
    this.screenWidth = 600;
    this.init();
  },
  methods: {
    async getAddress() {
      this.conflux = new this.$conflux({
        networkId: 1029,
        logger: console,
      });
      this.conflux.provider = window.conflux;

      let accounts = null;
      let _address = "";
      try {
        accounts = await conflux.request({ method: "cfx_requestAccounts" });
        _address = accounts + "";
        window._address = _address;
      } catch (error) {
        console.log("error");
      }
      if (_address === "") {
        try {
          accounts = await window.conflux.enable();
        } catch (error) {}
        // 新版本手机
        if (window.isArrayFn(accounts)) {
          _address = accounts[0];
        } else {
          const accounts2 = await window.conflux.send({ method: "cfx_accounts" });
          _address = accounts2.result[0];
        }
        window._address = _address;
      }
      localStorage.setItem("_address", _address);

      try {
        document.getElementById("accname").innerHTML =
          '<div class="qb_btn">' +
          _address.substring(0, 6) +
          "..." +
          _address.substring(45, _address.lenght) +
          "</div>";
      } catch (error) {}

      return _address;
    },

    toConvert(size) {
      if (!size) return 0;

      var num = 1000.0; //byte

      if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + "K"; //kb
      if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
      if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
      return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
    },

    async init() {
      this.$nextTick(async function () {
        if (document.body.clientWidth < 768) {
          this.$router.replace({ path: "/utilities_m" });
        }

        this.conflux = new this.$conflux({
          networkId: 1029,
          logger: console,
        });
        this.conflux.provider = window.conflux;

        const EXCHANG_V2 = await this.conflux.Contract({
          abi: EXCHANG_ABI,
          address: EXCHANG_ADDRESS,
        });

        this.$axios
          .get(this.$store.state.api + "/statistics/rate")
          .then(async (response) => {
            const array = response.data.rows;
            for (let index = array.length - 1; index >= 0; index--) {
              const element = array[index];
              //this.data.labels.push('aa');
              //const data = JSON.parse(element.data);
              this.data.datasets[0].data.push(element.val1); //+data.rate[0]
              this.data.datasets[1].data.push(element.val2);
              this.data.datasets[2].data.push(element.val3);

              var oldTime = new Date(element.created_at).getTime();
              var curday = new Date(oldTime).getDate();
              var curmonth = new Date(oldTime).getMonth();
              var curhours = new Date(oldTime).getHours(); //curmonth + 1 + "/" + curday + " " +
              curday = curhours + ":00:00";
              this.data.labels.push(curday);
            }
            //console.log(this.data);
            // 今日汇率
            const rate = await EXCHANG_V2.estimate();
            const t0 = Drip(+rate[0]).toCFX();
            const rate0 = parseFloat(t0).toFixed(4);
            //console.log(rate0);
            const t1 = Drip(+rate[1]).toCFX();
            const rate1 = parseFloat(t1).toFixed(4);
            //console.log(rate1);
            const t2 = Drip(+rate[2]).toCFX();
            const rate2 = parseFloat(t2).toFixed(4);
            //console.log(rate2);

            this.data.datasets[0].data.push(+rate0);
            this.data.datasets[1].data.push(+rate1);
            this.data.datasets[2].data.push(+rate2);

            /*var nowmonth = new Date().getMonth();
          let nowday = new Date().getDate();
          nowday = nowmonth + 1 + "/" + nowday;*/
            this.data.labels.push("now");

            new Chart(document.getElementById("myChart8"), {
              type: "line",
              data: this.data,
              options: {
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        let label = context.dataset.label || "";

                        if (label) {
                          label += ": ";
                        }
                        if (context.parsed.y !== null) {
                          label += context.parsed.y;
                        }
                        return label;
                      },
                    },
                  },
                },
              },
            });
          });

        // 币种对应地址
        const coinAddr = await EXCHANG_V2.get_Settings(); // 0地址 cfxtest:aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa6f0vrcsw [ACFX FC ArtT]
        this.option1[0].value = "" + coinAddr[0];
        this.option1[2].value = "" + coinAddr[1];
        this.option1[3].value = "" + coinAddr[2];
        this.option2[0].value = "" + coinAddr[0];
        this.option2[2].value = "" + coinAddr[1];
        this.option2[3].value = "" + coinAddr[2];
        this.option3[0].value = "" + coinAddr[0];
        this.option3[2].value = "" + coinAddr[1];
        this.option3[3].value = "" + coinAddr[2];
        this.option4[0].value = "" + coinAddr[0];
        this.option4[2].value = "" + coinAddr[1];
        this.option4[3].value = "" + coinAddr[2];

        this.coins2 = this.option1[0].value;

        this.estimate();

        const totalSupply = await EXCHANG_V2.totalSupply();
        const totalSupplyT = Drip(totalSupply).toCFX();
        this.LPTotalSum = parseFloat(totalSupplyT).toFixed(4);

        setTimeout(async () => {
          // LP
          const LPSTAKE_V2 = await this.conflux.Contract({
            abi: LPSTAKE_ABI,
            address: LPSTAKE_ADDRESS,
          });

          const lp = await LPSTAKE_V2.balanceOf(window._address);
          const t2 = Drip(lp).toCFX();
          this.LPstakedFull = parseFloat(t2);
          const num = parseFloat(t2).toFixed(2);
          this.LPstaked = num;

          // 矿池中 我的收益
          const MainArtPool = this.conflux.Contract({
            abi: MainArtPool_ABI,
            address: MainArtPool_ADDRESS,
          });
          const profitT = await MainArtPool.earned(window._address);
          const profitR = Drip(profitT).toCFX(); // 获取 CFX
          this.profit = parseFloat(profitR).toFixed(2); // My Rewards

          const totalSupply = await EXCHANG_V2.totalSupply();
          const totalSupplyT = Drip(totalSupply).toCFX();
          this.LPTotalSum = parseFloat(totalSupplyT).toFixed(4);
        }, 200);
      });
    },

    async estimate() {
      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });

      // 币 是否授权
      const balance0 = await conflux.cfx.getBalance(_address);
      const tbalance0 = Drip(balance0).toCFX(); // 获取 CFX
      const mybalance0 = parseFloat(tbalance0).toFixed(4);
      this.mybalance1 = mybalance0;
      // --------------------------
      const ArtCoinACFX = await this.conflux.Contract({
        abi: CONTRACT_ABI,
        address: this.option1[0].value,
      });
      this.isACFX = await ArtCoinACFX.isOperatorFor(EXCHANG_ADDRESS, _address);
      console.log(this.isACFX);
      const tbalance = Drip(await ArtCoinACFX.balanceOf(_address)).toCFX(); // 获取 CFX
      const mybalance = parseFloat(tbalance).toFixed(4);
      this.mybalance = mybalance;
      // -----------------
      const ArtCoinFC = await this.conflux.Contract({
        abi: CONTRACT_ABI,
        address: this.option1[2].value,
      });
      this.isFC = await ArtCoinFC.isOperatorFor(EXCHANG_ADDRESS, _address);
      console.log(this.isFC);
      const tbalance1 = Drip(await ArtCoinFC.balanceOf(_address)).toCFX(); // 获取 CFX
      const mybalance1 = parseFloat(tbalance1).toFixed(4);
      this.mybalance2 = mybalance1;
      // ----------------------------
      const ArtCoinArtT = await this.conflux.Contract({
        abi: CONTRACT_ABI,
        address: this.option1[3].value,
      });
      this.isArtT = await ArtCoinArtT.isOperatorFor(EXCHANG_ADDRESS, _address);
      console.log(this.isArtT);
      const tbalance2 = Drip(await ArtCoinArtT.balanceOf(_address)).toCFX(); // 获取 CFX
      const mybalance2 = parseFloat(tbalance2).toFixed(4);
      this.mybalance3 = mybalance2;

      const tbalance4 = Drip(await EXCHANG_V2.balanceOf(_address)).toCFX();
      this.LPBalanceFull =  parseFloat(tbalance4);
      const mybalance4 = parseFloat(tbalance4).toFixed(4);
      this.mybalance4 = mybalance4;

      this.LPBalance = mybalance4;
    },

    // 估算
    async exchange_estimate() {
      console.log(this.coins);
      console.log(this.coins2);

      if (+this.num === 0) {
        //alert('Cannot enter 0');
        return;
      }
      if (this.coins === this.coins2) {
        //alert('不能一样类型');
        return;
      }
      const _address = await window.getAddress();

      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });
      this.$toast.loading({
        message: "processing...",
        duration: 3000,
      });
      const rest = await EXCHANG_V2.Exchange_estimate(
        this.coins,
        this.coins2,
        +this.$drip.fromCFX(+this.num)
      );
      this.gusuan = +rest;
      console.log(this.gusuan);
      this.num2 = parseFloat(Drip(rest).toCFX()).toFixed(2);
      this.$toast.clear();
    },
    // 估算
    async exchange_estimate2() {
      if (+this.num22 === 0) {
        //alert('Cannot enter 0');
        return;
      }

      const _address = await window.getAddress();

      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });
      this.$toast.loading({
        message: "processing...",
        duration: 3000,
      });
      const rest = await EXCHANG_V2.estimate();
      this.num21 = parseFloat(+this.num22 / Drip(rest[0]).toCFX()).toFixed(2);
      this.num23 = parseFloat(+this.num22 / Drip(rest[1]).toCFX()).toFixed(2);
      this.num24 = parseFloat(+this.num22 / Drip(rest[2]).toCFX()).toFixed(2);
      /*
      this.num21 = parseFloat(Drip(rest).toCFX()).toFixed(2);
      const rest1 = await EXCHANG_V2.Exchange_estimate(
        CFX_COINS,
        this.option1[2].value,
        +this.$drip.fromCFX(+this.num22)
      );
      this.num23 = parseFloat(Drip(rest1).toCFX()).toFixed(2);
      const rest3 = await EXCHANG_V2.Exchange_estimate(
        CFX_COINS,
        this.option1[3].value,
        +this.$drip.fromCFX(+this.num22)
      );
      this.num24 = parseFloat(Drip(rest3).toCFX()).toFixed(2);*/
      this.$toast.clear();
    },
    // 估算
    async exchange_estimate3() {
      if (+this.singlelpsum1 === 0) {
        return;
      }

      const _address = await window.getAddress();

      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });
      this.$toast.loading({
        message: "processing...",
        duration: 3000,
      });
      try {
        const rest = await EXCHANG_V2.increase_liq_single_estimate(
          this.coins3,
          +this.$drip.fromCFX(+this.singlelpsum1)
        );
        const num = parseFloat(Drip(rest).toCFX()).toFixed(2);
        this.singlelpsum2 = num;
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("Increase is too much") > 0) {
          this.$dialog.alert({
            message: "Increase is too much",
          });
        }
        this.$toast.clear();
      }

      this.$toast.clear();
    },
    // 估算
    async exchange_estimate4() {
      if (+this.singlelpsum3 === 0) {
        return;
      }

      const _address = await window.getAddress();

      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });
      this.$toast.loading({
        message: "processing...",
        duration: 3000,
      });
      try {
        const rest = await EXCHANG_V2.decrease_liq_single_estimate(
          this.coins4,
          +this.$drip.fromCFX(+this.singlelpsum3)
        );
        const num = parseFloat(Drip(rest).toCFX()).toFixed(2);
        this.singlelpsum4 = num;
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("Decrease is too much,please lower the input") > 0) {
          this.$dialog.alert({
            message: "Decrease is too much,please lower the input",
          });
        }
        this.$toast.clear();
      }
      this.$toast.clear();
    },
    // 转换
    async exchange() {
      const _address = await window.getAddress();
      // 币种授权判断1
      const ArtCoin = await this.conflux.Contract({
        abi: CONTRACT_ABI,
        address: this.coins, //this.option1[0].value,
      });
      if (this.coins != this.option1[1].value) {
        const is = await ArtCoin.isOperatorFor(EXCHANG_ADDRESS, _address);
        if (!is) {
          this.$toast.loading({
            message: "contract authorization in progress...",
            duration: 0,
            overlay: true,
            forbidClick: true,
          });
          const collateral1 = await ArtCoin.authorizeOperator(
            EXCHANG_ADDRESS
          ).estimateGasAndCollateral({ from: _address });
          console.log(collateral1);
          await ArtCoin.authorizeOperator(EXCHANG_ADDRESS)
            .sendTransaction({
              from: _address,
              gas: collateral1.gasLimit,
              storageLimit: collateral1.storageCollateralized,
            })
            .executed();

          this.$toast.clear();
        }
      }

      if (+this.num === 0) {
        alert("Cannot enter 0");
        return;
      }
      if (this.coins === this.coins2) {
        alert("Cannot be the same coins");
        return;
      }
      this.$toast.loading({
        message: "processing...",
        duration: 0,
        overlay: true,
        forbidClick: true,
      });
      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });
      console.log(this.coins);
      console.log(this.coins2);
      console.log(this.num);
      const coins =
        this.coins === this.option1[1].value ? 0 : +this.$drip.fromCFX(+this.num);
      //console.log(coins);
      const value =
        this.coins === this.option1[1].value ? +this.$drip.fromCFX(+this.num) : 0;
      //console.log(value);
      try {
        const collateral = await EXCHANG_V2.Exchange(
          this.coins,
          this.coins2,
          +coins,
          +(this.gusuan - 10000000000),
          +(this.gusuan + 10000000000)
        ).estimateGasAndCollateral({
          from: _address,
          value: +value,
        });
        console.log(collateral);
        //return;
        const rest = await EXCHANG_V2.Exchange(
          this.coins,
          this.coins2,
          +coins,
          +(this.gusuan - 10000000000),
          +(this.gusuan + 10000000000)
        )
          .sendTransaction({
            from: _address,
            value: +value,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();
        this.estimate();
        this.$toast.clear();
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("Exchange amount out of the limits line.") > 0) {
          this.$dialog.alert({
            message: "Exchange amount out of the limits line.",
          });
        }
        this.$toast.clear();
      }
      this.init();
    },
    // 单独LP转换
    async exchange4() {
      if (this.coins3 != this.option3[1].value) {
        // 0 地址不检查授权
        const ArtCoin = await this.conflux.Contract({
          abi: CONTRACT_ABI,
          address: this.coins3,
        });
        const is = await ArtCoin.isOperatorFor(EXCHANG_ADDRESS, _address);
        if (!is) {
          this.$toast.loading({
            message: "contract authorization in progress...",
            duration: 0,
            overlay: true,
            forbidClick: true,
          });
          try {
            const collateral1 = await ArtCoin.authorizeOperator(
              EXCHANG_ADDRESS
            ).estimateGasAndCollateral({ from: _address });
            await ArtCoin.authorizeOperator(EXCHANG_ADDRESS)
              .sendTransaction({
                from: _address,
                gas: collateral1.gasLimit,
                storageLimit: collateral1.storageCollateralized,
              })
              .executed();

            this.$toast.clear();
          } catch (error) {
            this.$toast.clear();
            return;
          }
        }
      }
      if (+this.singlelpsum1 === 0) {
        alert("Cannot enter 0");
        return;
      }
      this.$toast.loading({
        message: "processing...",
        duration: 0,
        overlay: true,
        forbidClick: true,
      });
      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });
      const coins =
        this.coins3 === this.option3[1].value
          ? 0
          : +this.$drip.fromCFX(+this.singlelpsum1); // 合约
      console.log(coins);
      const value =
        this.coins3 === this.option3[1].value
          ? +this.$drip.fromCFX(+this.singlelpsum1)
          : 0;
      console.log(value);
      try {
        const collateral = await EXCHANG_V2.increase_liquidity_single(
          this.coins3,
          +coins
        ).estimateGasAndCollateral({
          from: _address,
          value: +value,
        });
        console.log(collateral);
        //return;
        const rest = await EXCHANG_V2.increase_liquidity_single(this.coins3, +coins)
          .sendTransaction({
            from: _address,
            value: +value,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();
        this.estimate();
        this.$toast.clear();
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("Exchange amount out of the limits line.") > 0) {
          this.$dialog.alert({
            message: "Exchange amount out of the limits line.",
          });
        }
        if (error.message.indexOf("Increase is too much") > 0) {
          this.$dialog.alert({
            message: "Increase is too much",
          });
        }
        this.$toast.clear();
      }
      this.init();
    },
    async exchange5() {
      if (this.coins4 != this.option4[1].value) {
        // 0 地址不检查授权
        const ArtCoin = await this.conflux.Contract({
          abi: CONTRACT_ABI,
          address: this.coins4,
        });
        const is = await ArtCoin.isOperatorFor(EXCHANG_ADDRESS, _address);
        if (!is) {
          this.$toast.loading({
            message: "contract authorization in progress...",
            duration: 0,
            overlay: true,
            forbidClick: true,
          });
          try {
            const collateral1 = await ArtCoin.authorizeOperator(
              EXCHANG_ADDRESS
            ).estimateGasAndCollateral({ from: _address });
            await ArtCoin.authorizeOperator(EXCHANG_ADDRESS)
              .sendTransaction({
                from: _address,
                gas: collateral1.gasLimit,
                storageLimit: collateral1.storageCollateralized,
              })
              .executed();

            this.$toast.clear();
          } catch (error) {
            this.$toast.clear();
            return;
          }
        }
      }
      if (+this.singlelpsum3 === 0) {
        alert("Cannot enter 0");
        return;
      }
      this.$toast.loading({
        message: "processing...",
        duration: 0,
        overlay: true,
        forbidClick: true,
      });
      const EXCHANG_V2 = await this.conflux.Contract({
        abi: EXCHANG_ABI,
        address: EXCHANG_ADDRESS,
      });
      const coins = +this.$drip.fromCFX(+this.singlelpsum3);
      console.log(coins);
      try {
        const collateral = await EXCHANG_V2.decrease_liquidity_single(
          this.coins4,
          +coins
        ).estimateGasAndCollateral({
          from: _address,
        });
        console.log(collateral);
        //return;
        const rest = await EXCHANG_V2.decrease_liquidity_single(this.coins4, +coins)
          .sendTransaction({
            from: _address,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();
        this.estimate();
        this.$toast.clear();
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("Exchange amount out of the limits line.") > 0) {
          this.$dialog.alert({
            message: "Exchange amount out of the limits line.",
          });
        }
        if (error.message.indexOf("Increase is too much") > 0) {
          this.$dialog.alert({
            message: "Increase is too much",
          });
        }
        this.$toast.clear();
      }
      this.init();
    },
    // 授权
    async approve(i) {
      try {
        const ArtCoin = await this.conflux.Contract({
          abi: CONTRACT_ABI,
          address: this.option1[+i].value,
        });
        console.log(ArtCoin);
        const is = await ArtCoin.isOperatorFor(EXCHANG_ADDRESS, _address);
        if (!is) {
          this.$toast.loading({
            message: "contract authorization in progress...",
            duration: 0,
            overlay: true,
            forbidClick: true,
          });
          const collateral1 = await ArtCoin.authorizeOperator(
            EXCHANG_ADDRESS
          ).estimateGasAndCollateral({ from: _address });
          console.log(collateral1);
          await ArtCoin.authorizeOperator(EXCHANG_ADDRESS)
            .sendTransaction({
              from: _address,
              gas: collateral1.gasLimit,
              storageLimit: collateral1.storageCollateralized,
            })
            .executed();

          this.$toast.clear();

          this.estimate();
        }
      } catch (error) {
        this.$toast.clear();
      }
    },
    // LP
    async exchange2() {
      if (+this.num22 === 0) {
        alert("Cannot enter 0");
        return;
      }
      if (!this.isACFX) {
        alert("Please authorize first");
        return;
      }
      if (!this.isFC) {
        alert("Please authorize first");
        return;
      }
      if (!this.isArtT) {
        alert("Please authorize first");
        return;
      }
      try {
        this.$toast.loading({
          message: "processing...",
          duration: 0,
          overlay: true,
          forbidClick: true,
        });
        const EXCHANG_V2 = await this.conflux.Contract({
          abi: EXCHANG_ABI,
          address: EXCHANG_ADDRESS,
        });
        const collateral = await EXCHANG_V2.increase_liquidity(
          window._address
        ).estimateGasAndCollateral({
          from: _address,
          value: +this.$drip.fromCFX(+this.num22),
        });
        console.log(collateral);
        await EXCHANG_V2.increase_liquidity(window._address)
          .sendTransaction({
            from: _address,
            value: +this.$drip.fromCFX(+this.num22),
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();
        this.estimate();
        this.$toast.clear();
      } catch (error) {
        this.$toast.clear();
      }
      this.init();
    },
    async exchange3() {
      if (+this.lpsum === 0) {
        alert("Cannot enter 0");
        return;
      }
      if (!this.isACFX) {
        alert("Please authorize first");
        return;
      }
      if (!this.isFC) {
        alert("Please authorize first");
        return;
      }
      if (!this.isArtT) {
        alert("Please authorize first");
        return;
      }
      try {
        this.$toast.loading({
          message: "processing...",
          duration: 0,
          overlay: true,
          forbidClick: true,
        });
        const EXCHANG_V2 = await this.conflux.Contract({
          abi: EXCHANG_ABI,
          address: EXCHANG_ADDRESS,
        });
        const collateral = await EXCHANG_V2.decrease_liquidity(
          +this.$drip.fromCFX(+this.lpsum)
        ).estimateGasAndCollateral({
          from: _address,
        });
        console.log(collateral);
        await EXCHANG_V2.decrease_liquidity(+this.$drip.fromCFX(+this.lpsum))
          .sendTransaction({
            from: _address,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();
        this.estimate();
        this.$toast.clear();
      } catch (error) {
        this.$toast.clear();
      }
      this.init();
    },
    async onStakeLPBalance() {
      if (+this.LPamount === 0) {
        alert("Cannot enter 0");
        return;
      }
      //function stakeLP(uint256 _amount) //LP质押功能，输入是数量  LPamount
      try {
        const EXCHANG_V2 = await this.conflux.Contract({
          abi: EXCHANG_ABI,
          address: EXCHANG_ADDRESS,
        });

        const is = await EXCHANG_V2.isOperatorFor(LPSTAKE_ADDRESS, window._address);
        if (!is) {
          this.$toast.loading({
            message: "contract authorization in progress...",
            duration: 0,
            overlay: true,
            forbidClick: true,
          });
          const collateral1 = await EXCHANG_V2.authorizeOperator(
            LPSTAKE_ADDRESS
          ).estimateGasAndCollateral({ from: window._address });
          console.log(collateral1);
          await EXCHANG_V2.authorizeOperator(LPSTAKE_ADDRESS)
            .sendTransaction({
              from: window._address,
              gas: collateral1.gasLimit,
              storageLimit: collateral1.storageCollateralized,
            })
            .executed();

          this.$toast.clear();
        }
        this.$toast.loading({
          message: "processing...",
          duration: 0,
          overlay: true,
          forbidClick: true,
        });
        const LPSTAKE_V2 = await this.conflux.Contract({
          abi: LPSTAKE_ABI,
          address: LPSTAKE_ADDRESS,
        });
        const collateral = await LPSTAKE_V2.stakeLP(
          +this.$drip.fromCFX(+this.LPamount)
        ).estimateGasAndCollateral({
          from: window._address,
        });
        await LPSTAKE_V2.stakeLP(+this.$drip.fromCFX(+this.LPamount))
          .sendTransaction({
            from: _address,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();
        this.init();
        this.$toast.clear();
      } catch (error) {
        this.$toast.clear();
      }
    },
    async onStakeLPstaked() {
      //function withdrawLP(uint256 _amount) //撤销质押功能，输入是数量  LPamount2
      if (+this.LPamount2 === 0) {
        alert("Cannot enter 0");
        return;
      }
      try {
        const EXCHANG_V2 = await this.conflux.Contract({
          abi: EXCHANG_ABI,
          address: EXCHANG_ADDRESS,
        });

        const is = await EXCHANG_V2.isOperatorFor(LPSTAKE_ADDRESS, window._address);
        if (!is) {
          this.$toast.loading({
            message: "contract authorization in progress...",
            duration: 0,
            overlay: true,
            forbidClick: true,
          });
          const collateral1 = await EXCHANG_V2.authorizeOperator(
            LPSTAKE_ADDRESS
          ).estimateGasAndCollateral({ from: window._address });
          console.log(collateral1);
          await EXCHANG_V2.authorizeOperator(LPSTAKE_ADDRESS)
            .sendTransaction({
              from: window._address,
              gas: collateral1.gasLimit,
              storageLimit: collateral1.storageCollateralized,
            })
            .executed();

          this.$toast.clear();
        }
        this.$toast.loading({
          message: "processing...",
          duration: 0,
          overlay: true,
          forbidClick: true,
        });
        const LPSTAKE_V2 = await this.conflux.Contract({
          abi: LPSTAKE_ABI,
          address: LPSTAKE_ADDRESS,
        });
        const collateral = await LPSTAKE_V2.withdrawLP(
          +this.$drip.fromCFX(+this.LPamount2)
        ).estimateGasAndCollateral({
          from: window._address,
        });

        await LPSTAKE_V2.withdrawLP(+this.$drip.fromCFX(+this.LPamount2))
          .sendTransaction({
            from: window._address,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();
        this.init();
        this.$toast.clear();
      } catch (error) {
        this.$toast.clear();
      }
    },
    async getReward() {
      this.$toast.loading({
        message: "processing...",
        duration: 0,
        overlay: true,
        forbidClick: true,
      });
      try {
        const MainArtPool = this.conflux.Contract({
          abi: MainArtPool_ABI,
          address: MainArtPool_ADDRESS,
        });

        const reward = await MainArtPool.getReward()
          .sendTransaction({
            from: _address,
          })
          .executed();
        this.$toast.clear();
        this.init();
      } catch (error) {
        this.$toast.clear();
      }
    },
    max1() {
      this.amount = Math.floor(this.balance / 1000) * 1000;
    },
    max2() {
      this.LPamount = this.LPBalanceFull;
    },
    max3() {
      this.LPamount2 = this.LPstakedFull;
    },
  },
};
